
import Vue from "vue";
import { authMapGetters } from "@/store/modules/auth";
import {
  getCompanyLabel,
  getSeaCoastTerritories
} from "../../../../helpers/companies";
import { ICompany } from "@/store/modules/companies/types";

export default Vue.extend({
  name: "CustomTerritoryViewer",
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    label: {
      type: String,
      required: false,
      default: "Territory Data"
    },
    hideTopBorder: {
      type: Boolean,
      required: false,
      default: false
    },
    companies: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    companyTerritories() {
      const { companyTerritories } = this.value.territoryInfo;
      const result = new Set<string>();

      this.companies.forEach((company: any) => {
        const findCompany = companyTerritories.find(
          (territory: any) => territory.companyNumber == company.companyNumber
        );

        if (findCompany) {
          result.add(
            `${getCompanyLabel(
              company.companyNumber,
              company.companyName,
              "quote"
            )} - ${findCompany.terr}`
          );
        }
      });

      return Array.from(result);
    },
    seaCoastTerritories() {
      return getSeaCoastTerritories(
        this.companies as ICompany[],
        this.value.territoryInfo.companyTerritories
      );
    }
  }
});
