
import * as types from "@/store/mutation-types";
import Vue from "vue";
import {
  discountsFormAtlasHO,
  discountsFormAtlasDF,
  discountsFormColonialDF,
  discountsFormColonialHO
} from "@/forms/shared/discounts";
import { quoteMapState, quoteMapMutations } from "@/store/modules/quote";
import { quoteDiscountsDefaults } from "@/helpers/defaultObjects";
import { authMapState } from "@/store/modules/auth";
import { checkIfIsAtlasCompany, isColonial } from "@/forms/utils/index";
import { accreditedBuilderMapActions } from "@/store/modules/accreditedBuilder";
import { get } from "lodash";
interface IDiscounts {
  discounts: any;
  forceUpdateKey: number;
  companionAutoCount: number;
  lossFreeExperienceCreditCount: number;
  validationData: any;
  accreditedBuilders: any;
  loading: boolean;
}
export default Vue.extend({
  name: "Discounts",
  data(): IDiscounts {
    return {
      forceUpdateKey: 0,
      discounts: {
        ...quoteDiscountsDefaults
      },
      companionAutoCount: 0,
      lossFreeExperienceCreditCount: 0,
      validationData: {},
      accreditedBuilders: [],
      loading: false
    };
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    const discounts = get(this.editing, "discounts", {});
    if (Object.keys(discounts).length) {
      this.editingField({
        key: "discounts",
        value: { ...this.discounts, ...this.editing.discounts }
      });
      if (discounts.accreditedBuilder) {
        this.getAccreditedBuilders({}).then(data => {
          this.accreditedBuilders = data;
        });
      }
    } else {
      this.editing["discounts"] = this.discounts;
    }
  },
  computed: {
    ...quoteMapState(["editing"]),
    ...authMapState(["initData"]),
    discountsFormFields(): any[] {
      let discounts: any[] = [];
      const accreditedBuilders = this.accreditedBuilders
        .map((builder: any) => {
          return {
            value: builder.accreditedBuilderName,
            label: builder.accreditedBuilderName
          };
        })
        .sort((a: any, b: any) => {
          return a.value.localeCompare(b.value);
        });
      if (
        checkIfIsAtlasCompany(this.editing.companyNumbers) &&
        this.editing.policyType !== "T"
      ) {
        const atlasDiscountParams = {
          yearOfRoof: this.editing.yearOfRoof,
          atlasCompanyNumbers: this.atlasCompanyNumbers,
          atlasCompanyNumbersWithout27: this.atlasCompanyNumbersWithout27
        };
        if (this.editing.policyType == "H") {
          discounts = discountsFormAtlasHO(
            atlasDiscountParams,
            accreditedBuilders
          );
        } else {
          discounts.push(...discountsFormAtlasDF(atlasDiscountParams));
        }
      }

      if (
        isColonial(this.editing.companyNumbers) &&
        this.editing.policyType !== "T"
      ) {
        if (this.editing.policyType === "D") {
          discounts.push(...discountsFormColonialDF());
        } else {
          const hasInsurorsIndemnitySelect = this.editing.companyNumbers.includes(
            32
          );
          discounts.push(
            ...discountsFormColonialHO(
              this.editing.yearBuild,
              this.editing.effectiveDate,
              hasInsurorsIndemnitySelect
            )
          );
        }
      }

      return discounts;
    },
    formValues(): any {
      return {
        ...this.editing.discounts,
        hasRoofYear: this.editing.yearOfRoof === "" ? false : true
      };
    },
    atlasCompanyNumbers(): number[] {
      return this.editing.companyNumbers.filter(
        (companyNumber: number) => companyNumber !== 20 && companyNumber !== 99
      );
    },
    atlasCompanyNumbersWithout27(): number[] {
      return this.editing.companyNumbers.filter(
        (companyNumber: number) =>
          companyNumber !== 20 &&
          companyNumber !== 99 &&
          companyNumber !== 27 &&
          companyNumber !== 29
      );
    }
  },
  methods: {
    ...quoteMapMutations({
      editDiscountField: types.EDIT_DISCOUNT_FIELD,
      editField: types.SET_EDIT_FIELD,
      editingField: "SET_EDIT_FIELD"
    }),
    ...accreditedBuilderMapActions(["getAccreditedBuilders"]),
    async formFieldChangeHandler({
      key,
      value
    }: {
      key: any;
      value: any;
    }): Promise<void> {
      if (key === "accreditedBuilder" && value) {
        try {
          if (!this.loading) {
            this.$emit("fetchingAccreditedBuilder");
            this.loading = true;
            this.accreditedBuilders = await this.getAccreditedBuilders({});
          }
        } catch (error) {
          this.$appNotifyError("Error fetching accredited builders");
          this.$bugSnagClient.notify(error);
        } finally {
          this.loading = false;
          this.$emit("fetchingAccreditedBuilderDone");
        }
      }
      if (
        (key === "companionAuto" || key === "companionAutoAtlas") &&
        value == true
      ) {
        if (this.companionAutoCount == 0) {
          this.$appNotifyError("Please Send Auto Declaration!", "Attention");
        }
        this.companionAutoCount = this.companionAutoCount === 0 ? 1 : 0;
      } else if (
        [
          "certificateExpirationDate",
          "colonialCertificateExpirationDate"
        ].includes(key) &&
        value
      ) {
        this.$appNotifyError(
          "Please Upload Fortified Roof Certificate",
          "Attention"
        );
      } else if (key === "lossFreeExperienceCredit" && value == true) {
        if (this.lossFreeExperienceCreditCount == 0) {
          this.$appNotifyError(
            "Requires 2 Consecutive Loss Free Years With Atlas",
            "Attention"
          );
        }
        this.lossFreeExperienceCreditCount =
          this.lossFreeExperienceCreditCount === 0 ? 1 : 0;
      }
      this.editDiscountField({
        key,
        value
      });
    },
    formFieldChangedVisiblyHandler(): void {
      this.$emit("change");
      this.forceUpdateKey++;
    },
    checkValidation(event: any): void {
      this.$emit("validation", event);
    }
  }
});
