
import {
  quoteMapActions,
  quoteMapGetters,
  quoteMapMutations,
  quoteMapState
} from "@/store/modules/quote";
import AddressViewer from "@/views/admin/personalLiabilityPolicy/SinglePersonalLiabilityPolicy/AddressViewer.vue";
import tenantPropertyRatingForm from "@/forms/shared/quote.tenant";
import homeOwnerPropertyRatingForm from "@/forms/shared/quote.home_owner";
import {
  goToCoApplicantForm,
  isCompletedStatus,
  objectDifference,
  toTitleCaseFromCamelCase
} from "@/helpers";
import QuoteSteps from "./QuoteSteps.vue";
import { authMapGetters, authMapState } from "@/store/modules/auth";
import { ICompany } from "@/store/modules/companies/types";
import { FormBlock } from "@/components/FormBuilder/types";
import { IQuote, IDiscount } from "@/store/modules/quote/types";
import RatingEndorsements from "@/components/EndorsementsEditor/RatingEndorsements.vue";
import { getQuoteDefaults } from "@/helpers/defaultObjectGenerators";
import {
  completedStatus,
  roofStructureOptions,
  companyLineOfBusiness,
  allRoofMaterialOptions
} from "@/helpers/selectOptions";
import TerritoryDataViewer from "@/views/shared/quotes/Components/TerritoryViewer.vue";
import Discounts from "@/components/Discounts/Discounts.vue";
import UnderwriterInfo from "./Components/UnderwriterInfo.vue";
import {
  getUserCompanies,
  quoteHasUnassignedUserCompanies
} from "@/helpers/companies";
import ModalBuildingAmount from "@/components/Dialog/ModalBuildingAmount.vue";
import {
  getContentsAmountPerBuildingAmount,
  getOtherStructuresAmountPerBuildingAmount,
  isColonial,
  setFieldObjectProperties,
  getFieldObjectProperty,
  mCompanyLessThanOrEqual3,
  shouldAddRoofMaterial,
  shouldAddTrampolineInFenceYardDF,
  hasColonialSeacoastSeaTerritoryOnly,
  hasSeacoastAndNotSeacoastSeaTerritory,
  UPPValidator,
  OTSValidator,
  hasColonialSeacoast,
  isPoolInApprovedFence,
  validateOccupiedDaily,
  maxDwellingValidator,
  hasSeacoastSeaTerrData,
  checkEndorsement
} from "@/forms/utils/index";
import { get, isEqual } from "lodash";
import dwellingFirePropertyRatingForm from "@/forms/shared/quote.dwelling_fire";
import {
  isValidFutureEffectiveDate,
  isValidPastEffectiveDate
} from "@/helpers/validateEffectiveDate";
import { isExemptedUserRole } from "@/helpers/index";
import {
  capitalizeFirstLetter,
  stripAgencyCode
} from "@/helpers/generalHelpers";
import BackDatingCoverage from "./Components/BackDatingCoverage.vue";
import VueWithMixins from "@/helpers/mixins";
import ApprovalCode from "@/components/Quotes/ApprovalCode";

interface IData {
  formRefreshKey: string;
  validation: { formIsValid: boolean; fieldsWithErrors?: any };
  ratingData: Record<string, unknown>;
  loading: boolean;
  deleteAction: boolean;
  companyErrorMessages: string[];
  errorMessage: string;
  propertyFieldBuildingAmountOptions: unknown[];
  discountValidation: Record<string, unknown>;
  nextRoute: any;
  needsApproval: boolean;
  needsYearBuiltApproval: boolean;
  endorsementsAreInvalid: boolean;
  modalText: string;
  loadingText: string;
  toRoute: string;
  genericErrorMessage: string;
  customErrors: { errorTitle: string; errorMessage: string }[];
}

export default VueWithMixins(ApprovalCode).extend({
  name: "property-rating",
  components: {
    AddressViewer,
    RatingEndorsements,
    TerritoryDataViewer,
    ModalBuildingAmount,
    Discounts,
    QuoteSteps,
    UnderwriterInfo,
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue"),
    saveChanges: () => import("@/components/Dialog/saveChanges.vue"),
    BackDatingCoverage
  },
  data(): IData {
    return {
      formRefreshKey: `form-${Math.random()
        .toString(36)
        .substring(2, 15)}`,
      validation: { formIsValid: false, fieldsWithErrors: [] },
      ratingData: {},
      loading: false,
      companyErrorMessages: [],
      errorMessage: "",
      propertyFieldBuildingAmountOptions: [],
      discountValidation: {},
      nextRoute: "",
      deleteAction: false,
      needsApproval: false,
      needsYearBuiltApproval: false,
      endorsementsAreInvalid: false,
      modalText: "",
      loadingText: "",
      toRoute: "",
      customErrors: [],
      genericErrorMessage: ""
    };
  },
  props: {
    quoteId: {
      type: String,
      required: true
    }
  },
  created() {
    this.removeUnauthorizedCompaniesIfNeeded()
      .then(() => {
        this.setAnalytics();
        if (!this.editing.limitDed3 && this.editing.policyType === "T") {
          this.editingField({ key: "limitDed3", value: "1" });
        }
        if (this.editing.policyType === "H") {
          if (!this.editing.limitLiab) {
            this.editingField({ key: "limitLiab", value: "300000" });
          }
          if (!this.editing.limitDed2) {
            this.editingField({ key: "limitDed2", value: "1.0000" });
          }
          if (!this.editing.limitMed) {
            this.editingField({ key: "limitMed", value: "5000" });
          }
        }

        if (this.editing.policyType === "D") {
          if (!this.editing.limitDed1) {
            this.editingField({ key: "limitDed1", value: "1.0000" });
          }
        }

        if (this.editing.yearOfRoof && this.editing.policyType !== "T") {
          //@ts-ignore
          const yearOfRoofCheckResponse = this.checkYearOfRoof({
            yearOfRoof: this.editing.yearOfRoof,
            yearBuild: this.editing.yearBuild,
            roofMaterial: this.editing.roofMaterial,
            companyNumbers: this.editing.companyNumbers,
            companyTerritories: this.companyTerritories
          });

          if (yearOfRoofCheckResponse) {
            const { success, message, type } = yearOfRoofCheckResponse;
            this.messages = [];
            this.clearYearOfRoofField(success, message, type, "mounted");
          }
        }
      })
      .catch((error: any) => {
        this.errorMessage = error.message as string;
        this.$bugSnagClient.notify(error);
      });

    this.displayCustomRiskAddressError();
  },
  async beforeRouteLeave(to, from, next): Promise<void> {
    //@ts-ignore
    this.toRoute = to?.name;
    const hasProfileUpdates = Object.keys(this.updatedFields).length > 0;
    if (!this.deleteAction && hasProfileUpdates) {
      this.nextRoute = next;
      this.$modal.show("discard-changes-modal");
    } else {
      return next();
    }
  },
  methods: {
    ...quoteMapMutations({
      updateAmountDWPCompanyOverrides: "SET_EDIT_OVERRIDE_FIELD",
      editingField: "SET_EDIT_FIELD",
      SET_EDIT: "SET_EDIT",
      SET_ITEM: "SET_ITEM"
    }),
    ...quoteMapActions([
      "saveQuotePropertyFields",
      "rateQuote",
      "getPropertyFieldBuildingAmountValidations"
    ]),
    handleAccreditedBuilderFetch() {
      this.loading = true;
      this.loadingText = "Fetch Accredited Builders. Please wait...";
    },
    handleAccreditedBuilderFetchDone() {
      this.loading = false;
      this.loadingText = "";
    },
    setAnalytics() {
      const analyticsRoofStructure = get(
        this.editing,
        "propertyAnalytics.capeDataResponse.property_features.roof_geometry",
        ""
      );
      const analyticsSquareFeet = get(
        this.editing,
        "propertyAnalytics.capeDataResponse.analytics.sqft",
        ""
      );
      const analyticsRoofCovering = get(
        this.editing,
        "propertyAnalytics.capeDataResponse.property_features.roof_covering",
        ""
      );
      const structure = roofStructureOptions.find(
        (option: any) =>
          option.label.toLowerCase() === analyticsRoofStructure.toLowerCase()
      );

      const covering = allRoofMaterialOptions.find(
        (option: any) =>
          option.label.toLowerCase() === analyticsRoofCovering.toLowerCase()
      );

      const dwellingFireRoofStructure = covering ? covering.value : "";
      const homeOwnerRoofStructure = structure ? structure.value : "";
      if (!get(this.editing, "roofStructure")) {
        this.editingField({
          key: "roofStructure",
          value:
            this.editing.policyType === "H"
              ? homeOwnerRoofStructure
              : dwellingFireRoofStructure
        });
      }
      if (!get(this.editing, "squareFeet")) {
        this.editingField({ key: "squareFeet", value: analyticsSquareFeet });
      }
    },
    async removeUnauthorizedCompaniesIfNeeded(): Promise<void> {
      const result = quoteHasUnassignedUserCompanies(
        this.editing.companyNumbers,
        this.userCompanies
      );
      if (result.hasUnassigned) {
        this.editingField({
          key: "companyNumbers",
          value: result.filteredCompanies
        });
        await this.saveQuote();
      }
    },
    scrollFirstInstanceOfErrorIntoView() {
      const fieldDive = (validation: any) => {
        const { fieldsWithErrors } = validation;
        if (fieldsWithErrors && fieldsWithErrors.length) {
          const field = fieldsWithErrors[0];
          this.scrollFieldElementIntoView(field);
          return true;
        }
        return false;
      };

      if (!fieldDive(this.validation)) fieldDive(this.discountValidation);
    },
    scrollFieldElementIntoView(field: string): void {
      if (!field) return;
      const element = document.querySelector(`#field_field_${field}`);
      if (element)
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center"
        });
    },
    async beforeRouteLeaveSave() {
      try {
        this.$modal.hide("discard-changes-modal");
        await this.saveQuote();
        if (this.toRoute === "property-ratings") {
          await this.doRateQuote(this.editing._id);
        }
        this.nextRoute();
      } catch (error) {
        this.nextRoute(false);
        this.$bugSnagClient.notify(error);
      }
    },
    async beforeRouteLeaveDiscardChanges() {
      try {
        this.$modal.hide("discard-changes-modal");
        this.nextRoute();
      } catch (error) {
        this.nextRoute(false);
        this.$bugSnagClient.notify(error);
      }
    },
    changeStateWideTo20(companyNumber: number): number {
      return companyNumber === 99 ? 20 : companyNumber;
    },
    doModalBuildingAmountButtonOkClick() {
      this.updateAmountDWPCompanyOverrides;
      this.$modal.hide("modal-building-amount");
    },
    async doRateQuote(quoteId: any) {
      await this.rateQuote({ quoteId });
    },
    async rateUserQuote() {
      try {
        await this.doRateQuote(this.editing._id);
        this.$router.push(`/quotes/${this.quoteId}/property-ratings`);
      } catch (error) {
        const { message } = error as Error;
        this.genericErrorMessage = message;
      } finally {
        this.$modal.hide("printQuoteModal");
      }
    },
    async toolbarSelectItem(event: string): Promise<void> {
      switch (event) {
        case "rate":
          if (!this.isCurrentUserAdmin) {
            this.$modal.show("printQuoteModal");
            return;
          }
          await this.rateUserQuote();
          break;
        case "save": {
          await this.saveQuote();
          this.scrollFirstInstanceOfErrorIntoView();
          break;
        }
        case "cancel-goto-list":
          this.$router.push("/quotes").catch(() => {});
          break;
        case "next":
          this.$router.push(`/quotes/${this.quoteId}/property-ratings`);
          break;
        case "edit-risk-address":
          this.$router.push(`/quotes/${this.quoteId}/risk-address`);
          break;
        case "edit-territory":
          this.$router.push(`/quotes/${this.quoteId}/territory`);
          break;
        case "addCoApplicant": {
          const quoteId = this.$route.params.quoteId;
          goToCoApplicantForm(this.$router, quoteId);
          break;
        }
        case "cancel":
          this.$router.go(-1);
          break;
      }
    },
    formFieldChangeHandler(payload: any) {
      this.ratingData[payload.key as any] = payload.value;
      this.ratingData = { ...this.ratingData };
    },
    companyChangedHandler(companyNumber: number[]): void {
      if (companyNumber.includes(0)) {
        companyNumber = this.userCompanies
          .map((company: any) => company.value)
          .filter((company: any) => company !== 0);
        this.editingField({ key: "companyNumbers", value: companyNumber });
      }

      if (!Array.isArray(companyNumber)) {
        companyNumber = [companyNumber];
      }
      if (!companyNumber.length) {
        const companyNumbers = this.editing.companyNumbers;
        let theCompanyNumber = [companyNumbers[0]];
        this.editingField({
          key: "companyNumbers",
          value: theCompanyNumber
        });
        this.$appNotifyError("At least one company must be selected");
      } else {
        this.editingField({ key: "companyNumbers", value: companyNumber });
      }

      if (this.editing.yearOfRoof && this.editing.policyType !== "T") {
        //@ts-ignore
        const yearOfRoofCheckResponse = this.checkYearOfRoof({
          yearOfRoof: this.editing.yearOfRoof,
          yearBuild: this.editing.yearBuild,
          roofMaterial: this.editing.roofMaterial,
          companyNumbers: this.editing.companyNumbers,
          companyTerritories: this.companyTerritories
        });
        if (yearOfRoofCheckResponse) {
          const { success, message, type } = yearOfRoofCheckResponse;
          this.messages = [];
          this.clearYearOfRoofField(success, message, type, "company");
        }
      }

      if (this.editing.occupiedDaily) {
        const response = validateOccupiedDaily(
          this.editing.occupiedDaily,
          this.editing.policyType,
          this.editing.companyNumbers
        );

        if (!response) {
          this.removeCompaniesWithoutDailyOccupiedVacantOption();
        }
      }
    },
    async saveCompanies(): Promise<void> {
      try {
        this.doSave();
      } catch (error) {
        this.$appNotifyError("Error saving quote");
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async saveFields(): Promise<void> {
      try {
        this.doSave();
      } catch (error) {
        this.$appNotifyError("Error saving quote");
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async saveQuote(): Promise<void> {
      try {
        if (Object.keys(this.updatedFields || {}).length === 0) return;
        if (this.updatedFields.endorsements) {
          this.updatedFields.endorsements = this.editing.endorsements;
        } else if (this.updatedFields.discounts) {
          this.updatedFields.discounts = this.editing.discounts;
        }
        await this.doSave();
        const status = this.editing.status === "draft" ? "as draft" : "";
        this.$appNotifySuccess(`Quote has been saved ${status}.`);
      } catch (error) {
        const { message = "Error saving quote" } = error as Error;
        this.$appNotifyError(message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async doSave() {
      this.loading = true;
      this.loadingText = "Saving Quote. Please wait...";
      const updatedQuote = await this.saveQuotePropertyFields({
        id: this.$route.params.quoteId,
        payload: this.updatedFields
      });
      this.SET_EDIT(getQuoteDefaults(updatedQuote.data));
      this.SET_ITEM(getQuoteDefaults(updatedQuote.data));
    },
    canAddCompanyErrorMessage(
      errorMessage: string,
      companyNumber: number
    ): boolean {
      if (this.companyErrorMessages.includes(errorMessage)) return false;
      if ([32, 99].includes(companyNumber)) return false; //this is a sentinel company for colonial seacoast.
      if (
        this.editing.companyNumbers &&
        this.editing.companyNumbers.length > 0
      ) {
        return this.editing.companyNumbers.includes(companyNumber);
      }
      return true;
    },
    async onFormFieldChanged({
      key,
      value
    }: {
      key: string | IDiscount | IQuote;
      value: any;
    }) {
      if (key === "trampolineInFenceYard" && value === "No") {
        this.modalText = "Personal Liability policy ineligible";
        this.$modal.show("alertModal");
      }
      if (isColonial(this.editing.companyNumbers) && key === "yearOfRoof") {
        this.editingField({
          //@ts-ignore
          key: "discounts.yearOfRoof",
          value
        });
      }
      if (key === "yearBuild") {
        let ageOfHome = new Date().getFullYear() - value;

        this.editingField({
          //@ts-ignore
          key: "discounts.ageOfHome",
          value: ageOfHome
        });

        if (this.editing.yearOfRoof && this.editing.policyType !== "T") {
          //@ts-ignore
          const yearOfRoofCheckResponse = this.checkYearOfRoof({
            yearOfRoof: this.editing.yearOfRoof,
            yearBuild: value,
            roofMaterial: this.editing.roofMaterial,
            companyNumbers: this.editing.companyNumbers,
            companyTerritories: this.companyTerritories
          });
          if (yearOfRoofCheckResponse) {
            const { success, message, type } = yearOfRoofCheckResponse;
            this.messages = [];
            this.clearYearOfRoofField(success, message, type);
          }
        }
      }
      if (key === "amountDWP") {
        this.editingField({
          key: "amountDWPCompanyOverrides",
          value: []
        });
      }

      if (key === "yearOfRoof" && this.editing.policyType !== "T") {
        const { success, message, type } = this.checkYearOfRoof({
          yearOfRoof: value,
          yearBuild: this.editing.yearBuild,
          roofMaterial: this.editing.roofMaterial,
          companyNumbers: this.editing.companyNumbers,
          companyTerritories: this.companyTerritories
        });
        this.messages = [];

        if (this.clearYearOfRoofField(success, message, type)) {
          return;
        }
      }

      if (
        key === "roofMaterial" &&
        this.editing.yearOfRoof &&
        this.editing.policyType !== "T"
      ) {
        const { success, message, type } = this.checkYearOfRoof({
          yearOfRoof: this.editing.yearOfRoof,
          yearBuild: this.editing.yearBuild,
          roofMaterial: value,
          companyNumbers: this.editing.companyNumbers,
          companyTerritories: this.companyTerritories
        });
        this.messages = [];
        this.clearYearOfRoofField(success, message, type);
      }
      if (
        (key === "occupancy" &&
          this.editing.policyType === "H" &&
          value === "T") ||
        (key === "poolApprovedFence" &&
          !isPoolInApprovedFence(value, this.editing.policyType))
      ) {
        this.modalText = "Risk is prohibited";
        this.$modal.show("alertModal");
      }
      if (key === "effectiveDate") {
        this.validateEffectiveDate(key, value);
      }

      if (key === "occupiedDaily") {
        const response = validateOccupiedDaily(
          value,
          this.editing.policyType,
          this.editing.companyNumbers
        );

        if (!response) {
          this.removeCompaniesWithoutDailyOccupiedVacantOption();
        }
      }
      //@ts-ignore
      this.editingField({ key, value });
    },
    validateEffectiveDate(key: string, value: string | Date) {
      const agentCode = stripAgencyCode(
        get(this.$getCurrentUser, "agentCode", "")
      ).toLowerCase();
      const exemptedRole = isExemptedUserRole(
        get(this.$getCurrentUser, "role", "")
      );
      const agentCodeFrmk = agentCode === "frmk";

      if (!isValidFutureEffectiveDate(value)) {
        this.$appNotifyError(
          "Effective date is more than 120 days in the future"
        );
        //@ts-ignore
        this.editingField({ key, value });
        //@ts-ignore
        this.editingField({ key, value: "" });
        return;
      } else if (
        !agentCodeFrmk &&
        !exemptedRole &&
        !isValidPastEffectiveDate(value, 4)
      ) {
        this.$appNotifyError(
          "Backdating of Coverage Requires Underwriting Approval. Please Call Your Underwriter"
        );
        //@ts-ignore
        this.editingField({ key, value });
        //@ts-ignore
        this.editingField({ key, value: "" });
        return;
      }
    },
    clearYearOfRoofField(
      success: boolean,
      message: string,
      type: string,
      from?: string,
      key = "yearOfRoof"
    ): void | true {
      if (!success) {
        if (message) {
          this.messages.push(message);
        }
        let displayMessages = "";

        if (["invalid", "risk"].includes(type) && from !== "mounted") {
          const uniqueStrings = new Set(this.messages);
          this.messages = Array.from(uniqueStrings);
          displayMessages = this.messages.join("\n");
        }

        {
          if (displayMessages) {
            this.$appNotifyError(displayMessages.trim());
          }

          if (type !== "call") {
            this.needsApproval = false;
            //@ts-ignore
            this.editingField({ key, value: "" });
            if (type === "risk" && from !== "company") {
              this.removeColonialSeacoastCompany();
            }
            return true;
          }
        }
      }
    },
    removeColonialSeacoastCompany() {
      if (hasColonialSeacoast(this.editing.companyNumbers)) {
        let companyNumbers = this.editing.companyNumbers;

        companyNumbers = companyNumbers.filter(
          (companyNumber: number) => companyNumber !== 20
        );
        this.editingField({
          key: "companyNumbers",
          value: companyNumbers
        });
        this.messages.push(
          "Roadrunner Indemnity Company Seacoast company has been removed"
        );
      }
    },
    removeCompaniesWithoutDailyOccupiedVacantOption() {
      let companyNumbers = this.editing.companyNumbers;
      companyNumbers = companyNumbers.filter(
        (companyNumber: number) =>
          companyNumber !== 20 &&
          companyNumber !== 99 &&
          companyNumber !== 32 &&
          companyNumber !== 27 &&
          companyNumber !== 29
      );

      this.editingField({
        key: "companyNumbers",
        value: companyNumbers
      });
      this.$appNotifyError(
        "Roadrunner Indemnity Company and Spinnaker Insurance Co. has been removed because of the vacant option selected."
      );
    },
    async amountGeneration() {
      if (!this.editing.amountDWP) {
        return;
      }
      setFieldObjectProperties(this.propertyRatingForm, "amountDWP", {
        loading: true
      });
      this.refreshFormKey();

      const { data } = await this.getPropertyFieldBuildingAmountValidations({
        quoteId: this.editing._id,
        buildingAmount: this.editing.amountDWP,
        squareFeet: this.editing.squareFeet,
        companyNumbers: this.editing.companyNumbers,
        structureType: this.editing.structureType,
        endorsements: this.editing.endorsements
      });
      let fieldOptions: {
        loading: boolean;
        descriptionHTML: string;
        labelIcon: undefined | "info";
        labelIconTooltip: undefined | string;
      } = {
        loading: false,
        descriptionHTML: "",
        labelIcon: undefined,
        labelIconTooltip: undefined
      };
      if (data.length > 0) {
        this.propertyFieldBuildingAmountOptions = data;
        fieldOptions = {
          loading: false,
          descriptionHTML: `<div class="text-red-500 font-semibold"><div>Amount is less than minimum dwelling amount per square footage</div><div>Click here to select different amounts per form type.</div></div>`,
          labelIcon: "info",
          labelIconTooltip:
            "Click here to select different amounts per form type"
        };
      }
      setFieldObjectProperties(
        this.propertyRatingForm,
        "amountDWP",
        fieldOptions
      );
      //if ok, save here

      const isDF = this.editing.policyType == "D";
      this.editingField({
        key: "amountUPP",
        value: isDF
          ? this.editing?.amountUPP
          : getContentsAmountPerBuildingAmount()
      });
      this.editingField({
        key: "amountOTS",
        value: isDF
          ? this.editing.amountOTS
          : getOtherStructuresAmountPerBuildingAmount()
      });
      this.refreshFormKey();
      this.$appNotifySuccess(
        "The values for Content Amount and Other Structures have been updated"
      );
    },
    async formFieldBlur({ key }: { key: string; value?: string }) {
      if (key === "amountDWP") {
        await this.amountGeneration();
      }
    },
    async handleFieldClickFieldArea({
      key,
      area
    }: {
      key: string;
      area: string;
    }) {
      if (key === "amountDWP") {
        switch (area) {
          case "error":
          case "description":
          case "label-icon":
            this.$modal.show("modal-building-amount");
            break;
        }
      }
    },
    refreshFormKey() {
      this.formRefreshKey = `form-${Math.random()
        .toString(36)
        .substring(2, 15)}`;
    },
    runAmountsValidations() {
      const {
        amountDWP,
        amountUPP,
        policyType,
        amountOTS,
        amountDWPCompanyOverrides
      } = this.editing;

      const { success: amountDWPValid } = maxDwellingValidator(amountDWP);

      if (
        policyType === "D" &&
        (!amountDWP || !/^\d*$/.test(amountDWP) || !amountDWPValid)
        // ||
        // (amountUPP &&
        //   (!/^\d*$/.test(amountUPP) || parseInt(amountUPP) < 1000) &&
        //   amountDWPCompanyOverrides.length == 0)
      ) {
        this.validation["fieldsWithErrors"].push("amountDWP");
        return false;
      } else if (
        policyType === "H" &&
        !this.contentAmountValid &&
        (!amountDWP ||
          !/^\d*$/.test(amountDWP) ||
          !amountDWPValid ||
          !amountUPP ||
          !/^\d*$/.test(amountUPP) ||
          !amountOTS ||
          !/^\d*$/.test(amountOTS) ||
          !OTSValidator(amountOTS)) &&
        amountDWPCompanyOverrides.length == 0
      ) {
        this.validation["fieldsWithErrors"].push("amountOTS");
        return false;
      }
      return true;
    },
    clearNonWeatherClaimsFields(newValue: any, oldValue: any) {
      if (
        this.editing &&
        this.editing.numberOfNonWeatherClaims &&
        !isEqual(newValue, oldValue)
      ) {
        this.editingField({ key: "numberOfNonWeatherClaims", value: 0 });
        this.$appNotify({
          title: "Number of Non-Weather or Non-Appliance Claims cleared",
          type: "success",
          message: this.__getText("fields", "nonWeatherClaimsCleared"),
          offset: 230,
          position: "top-right"
        });
      }
    },
    clearYearBuiltField(newValue: any, oldValue: any) {
      if (
        this.editing &&
        this.editing.yearBuild &&
        !isEqual(newValue, oldValue)
      ) {
        this.editingField({ key: "yearBuild", value: "" });
      }
    },
    showUnderwriterCodeField() {
      return this.needsApproval
        ? this.needsApproval
        : this.needsYearBuiltApproval;
    },
    displayCustomRiskAddressError() {
      const ratingValidations = get(
        this.editing,
        "ratingValidations.fieldsWithErrors",
        []
      );
      if (ratingValidations.length) {
        ratingValidations.map((error: any) => {
          let errorTitle = "";
          let errorMessage = "";
          switch (true) {
            case error === "riskAddress":
              errorTitle = "Risk Address";
              errorMessage = "Risk address is not valid";
              break;
            case error === "effectiveDate":
              this.validateEffectiveDate(error, this.editing.effectiveDate);
              // errorTitle = "Effective Date";
              // errorMessage = " Please Check Effective Date";
              break;
            default:
              errorTitle = error;
              errorMessage = error;
              break;
          }
          if (errorTitle && errorMessage) {
            this.customErrors.push({
              errorTitle,
              errorMessage
            });
          }
        });
      }
    },
    completedQuoteStatus(status: completedStatus | "deleted"): boolean {
      return (
        isCompletedStatus(status as completedStatus) || "deleted" == status
      );
    },
    toTitleCase(str: string): string {
      return toTitleCaseFromCamelCase(str);
    },
    capitalizeFirst(str: string) {
      if (!str) return "";
      return capitalizeFirstLetter(str);
    }
  },
  computed: {
    ...quoteMapState(["makingApiRequest", "editing"]),
    ...quoteMapGetters(["getQuoteById"]),
    ...authMapState(["initData"]),
    ...authMapGetters(["isCurrentUserAdmin"]),
    formValueDefaults(): any {
      return getQuoteDefaults({});
    },
    contentAmountValid(): boolean {
      if (!this.editing?.amountUPP) return false;
      const { success, message } = UPPValidator();
      this.errorMessage = message;
      return success;
    },
    companyTerritories(): any[] {
      return get(
        this.editing,
        "territoryData.territoryInfo.companyTerritories",
        []
      );
    },
    fieldsWithErrorsReasons(): Record<
      string,
      {
        message: string;
        url: string;
      }
    > {
      return this.ratingValidations?.fieldsWithErrorsReasons || {};
    },
    displayApplicantName(): boolean {
      const applicantFirstName =
        get(this.editing, "quoteApplication.applicant.firstName", "") || "";
      const applicantLastName =
        get(this.editing, "quoteApplication.applicant.lastName", "") || "";
      return applicantFirstName && applicantLastName;
    },
    isCurrentUserBanned(): boolean {
      return get(this.$getCurrentUser, "isBanned", false);
    },

    cannotEditQuote(): boolean {
      const quoteStatus = get(this.editing, "status", "");
      return this.isCurrentUserBanned || this.completedQuoteStatus(quoteStatus);
    },
    applicant(): Record<string, any> {
      return (
        (this.editing.quoteApplication &&
          this.editing.quoteApplication.applicant) ||
        {}
      );
    },
    getFieldsToIgnore(): any[] {
      let fieldsToIgnore: any[] = [
        "billing",
        "discounts.colonialCertificateExpirationDate",
        "discounts.certificateExpirationDate",
        "discounts.accreditedBuilderName",
        "discounts.hvacInstallationYear",
        "discounts.ageOfInsured",
        "discounts.yearInstalledAtlas",
        "wiringUpdateYear",
        "plumbingUpdateYear",
        "heatingUpdateYear",
        "poolApprovedFence",
        "trampolineApprovedFence",
        "poolDivingBoard",
        "poolSlide",
        "roofMaterial",
        "deadBolts",
        "fireExtinguisherOnPremises",
        "trampolineOnPremises",
        "trampolineInFenceYard",
        "dwellingOnSeawardIfOfHwy59",
        "yearOfRoof"
      ];
      if (this.editing.policyType == "D") {
        fieldsToIgnore.push("amountUPP");
      }
      if (this.editing.policyType !== "T") {
        fieldsToIgnore.push("amountDWP");
      }

      return fieldsToIgnore;
    },
    territoryDataViewerCompanies(): any[] {
      const companies = get(this.editing, "companyTerritoryData", []);
      const selectedCompanies = get(this.editing, "companyNumbers", []);
      return companies.filter((company: any) =>
        selectedCompanies.includes(company.companyNumber)
      );
    },
    updatedFields(): any {
      const edited = this.editing;
      const original = this.originalQuote;
      return edited && original
        ? objectDifference(edited, original, this.getFieldsToIgnore)
        : {};
    },
    originalQuote(): IQuote {
      return this.getQuoteById(this.$route.params.quoteId);
    },
    company(): ICompany | undefined {
      return this.initData?.companies.find(
        (company: ICompany) =>
          company.companyNumber === this.editing.companyNumber
      );
    },
    companyName(): string {
      return this.company ? this.company.companyName : "Unknown Company";
    },
    formValues(): any {
      const companyNumbers = get(this.editing, "companyNumbers", []);
      const companies = this.companiesData
        ? this.companiesData.filter((company: any) => {
            return companyNumbers.includes(company.companyNumber);
          })
        : [];

      const endorsements = get(this.editing, "endorsements", []).map(
        (endorsement: any) => {
          return endorsement.formNumber;
        }
      );

      const includesRelevantEndorsements = checkEndorsement(
        ["CDW_APW_0716", "CL2", "TDP_849"],
        endorsements
      );

      const terrSeacoast = hasSeacoastSeaTerrData(this.companyTerritories);
      const terrStatewide = !terrSeacoast;

      return {
        ...this.editing,
        shouldAddRoofMaterial: shouldAddRoofMaterial(companies, companyNumbers),
        hasSeacoastAndNotSeacoastSeaTerritory: hasSeacoastAndNotSeacoastSeaTerritory(
          this.companyTerritories,
          companyNumbers
        ),
        hasColonialSeacoastSeaTerritoryOnly: hasColonialSeacoastSeaTerritoryOnly(
          this.companyTerritories,
          companyNumbers
        ),
        includesRelevantEndorsements,
        terrSeacoast,
        terrStatewide,
        mCompanyLessThanOrEqual3: mCompanyLessThanOrEqual3(companies),
        isColonial: isColonial(companyNumbers),
        shouldAddTrampolineInFenceYardDF: shouldAddTrampolineInFenceYardDF(
          companyNumbers
        )
      };
    },
    propertyRatingForm(): FormBlock[] {
      let base = [];
      if (this.editing) {
        const policyType = this.editing.policyType;

        const companies = this.companiesData.filter((company: any) => {
          return this.editing.companyNumbers.includes(company.companyNumber);
        });
        const baseParams = {
          companyNumbers: this.editing.companyNumbers,
          billingValue: this.editing.billing,
          user: this.$getCurrentUser,
          policyType,
          createdOn: this.editing.createdOn
        };

        const additionalParams = {
          endorsements: this.editing.endorsements,
          companies,
          companyTerritories: this.companyTerritories
        };
        if (policyType === "T") {
          base = tenantPropertyRatingForm(baseParams);
        } else if (policyType === "H") {
          base = homeOwnerPropertyRatingForm({
            ...additionalParams,
            ...baseParams
          });
        } else if (policyType === "D") {
          base = dwellingFirePropertyRatingForm({
            ...additionalParams,
            ...baseParams
          });
        }
      }
      return base;
    },
    ratingValidations(): Record<string, any> {
      return get(this.editing, "ratingValidations", {});
    },
    canRate(): boolean {
      return this.validation.formIsValid;
    },
    primaryTopActionButton(): {
      text: string;
      key: string;
      disabled?: boolean;
      loading?: boolean;
    } | null {
      const hasChanges = Object.keys(this.updatedFields || {}).length !== 0;
      let command: any = {
        text: "Save Quote",
        key: "save",
        loading: this.makingApiRequest || this.loading,
        disabled: this.disableRateButton
      };
      if (
        !this.quoteHasBeenSubmitted &&
        !this.quoteHasBeenDeleted &&
        !hasChanges
      ) {
        command = {
          text: "Next",
          key: "rate",
          disabled: this.disableRateButton,
          loading: this.makingApiRequest || this.loading
        };
      }
      if (this.hasNoRatingCompanies) {
        command = {};
      }
      return command;
    },
    disablePrimaryTopActionButton(): boolean {
      if (
        this.editing &&
        this.canRate &&
        this.runAmountsValidations() &&
        Object.keys(this.editing.ratingValidations).length &&
        this.editing.ratingValidations.hasValidRiskAddress &&
        this.editing.ratingValidations.hasTerritoryInformation &&
        this.editing.ratingValidations.canRateQuote &&
        this.discountValidation.formIsValid &&
        this.editing.ratingValidations.validEffectiveDate
      ) {
        return false;
      } else {
        return true;
      }
    },
    companiesData(): ICompany[] {
      if (this.$getCurrentUser && this.$getCurrentUser.role === "admin") {
        return this.initData?.companies || [];
      } else {
        return get(this.$getCurrentUser, "companiesData", []);
      }
    },
    companyOptions(): any[] {
      const excludedCompanies = [18, 21, 23, 24, 25];
      const companies = this.companiesData.filter((company: any) => {
        return !excludedCompanies.includes(company.companyNumber);
      });

      const options = [];
      for (const company of companies) {
        let label = company.companyName;
        if (company.companyNumber === 27) {
          label = " Spinnaker Insurance (Harris & Fort Bend ONLY)";
        } else if (company.companyNumber === 28) {
          label = " Spinnaker Insurance (Tier 1 & Remaining Tier 2)";
        }

        options.push({
          value: company.companyNumber,
          label: `${company.companyNumber} - ${label}`
        });
      }
      options.sort((firstCompany: any, secondCompany: any) => {
        const first = firstCompany.value;
        const second = secondCompany.value;
        if (first > second) return 1;
        if (second > first) return -1;
        return 0;
      });
      return options;
    },
    pageTitle(): string {
      const policyType = get(this.editing, "policyType", "");
      const lineOfBusiness = companyLineOfBusiness.find(
        (option: any) => option.value === policyType
      );
      const lineOfBusinessLabel = lineOfBusiness ? lineOfBusiness.label : "";
      return `Property Rating - ${lineOfBusinessLabel}`;
    },
    userCompanies(): any[] {
      const companyData = get(
        this.editing,
        "companyValidations.allowedCompanies",
        []
      );
      const companies = getUserCompanies("quote", companyData);
      const companyNumbersInTerritory =
        this.editing && this.editing.companyTerritoryData
          ? this.editing.companyTerritoryData.map(
              (company: any) => company.companyNumber
            )
          : [];

      const companiesInTerritory = companies.filter((company: any) => {
        const isCompanyInTerritory = companyNumbersInTerritory.includes(
          company.value
        );
        if (isCompanyInTerritory) {
          return isCompanyInTerritory;
        }
      });

      if (companiesInTerritory.length) {
        companiesInTerritory.unshift({
          label: "Select all companies",
          value: 0
        });
      }

      return companiesInTerritory;
    },

    hasNoRatingCompanies(): boolean {
      return this.userCompanies.length === 0;
    },

    quoteHasBeenSubmitted(): boolean {
      return !!(
        this.editing &&
        this.editing.status === "submitted" &&
        this.editing.ratingValidations &&
        this.editing.ratingValidations.hasPolicyNumber
      );
    },
    hasRatings(): boolean {
      return !!(
        this.editing &&
        this.editing.ratings &&
        this.editing.ratings.length > 0
      );
    },
    topActionButtonSecondary(): {
      text: string;
      key: string;
      subItems?: { title: string; command: string }[];
    } {
      if (this.quoteHasBeenSubmitted) {
        return { text: "Next", key: "next" };
      }
      const subItems = [
        { title: "Cancel", command: "cancel-goto-list" },
        { title: "Edit Applicant Details", command: "edit-risk-address" },
        { title: "Edit Territory Data", command: "edit-territory" }
      ];

      if (this.hasRatings) {
        subItems.unshift({ title: "Next", command: "next" });
      }
      return {
        text: "Actions",
        key: "actions",
        subItems: subItems.sort((a: any, b: any) => {
          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;
          return 0;
        })
      };
    },
    quoteTerritory(): string {
      if (this.editing && this.editing.territoryData) {
        return this.editing.territoryData.territoryInfo.terr;
      }
      return "";
    },
    quoteHasBeenDeleted(): boolean {
      return !!(this.editing && this.editing.deleted);
    },
    disableRateButton(): boolean {
      return this.endorsementsAreInvalid || this.disablePrimaryTopActionButton;
    },
    byEzlynx(): boolean {
      return !!(
        this.editing &&
        this.editing.onBehalfOfEmail &&
        this.editing.onBehalfOfAgentCode
      );
    },
    getFieldsWithErrors(): any[] {
      if (this.validation && this.validation.fieldsWithErrors) {
        const textFieldResults = this.validation.fieldsWithErrors.map(
          (field: string) =>
            getFieldObjectProperty(this.propertyRatingForm, field, "label")
        );
        const othersResults = this.validation.fieldsWithErrors.map(
          (field: string) =>
            getFieldObjectProperty(this.propertyRatingForm, field, "preamble")
        );
        const extras = [
          { key: "amountDWP", label: "Building Amount" },
          { key: "amountUPP", label: "Contents Amount" },
          { key: "amountOTS", label: "Other Structures" },
          { key: "waterLimit", label: "Water Limit" },
          { key: "limitedSlabFoundation", label: "Limited Slab/Foundation" }
        ];
        extras.forEach((item: { key: string; label: string }) => {
          if (
            (item.key === "waterLimit" ||
              item.key === "limitedSlabFoundation") &&
            ![99, 32].some(num => this.editing.companyNumbers.includes(num))
          ) {
            return;
          }
          if (!this.editing[item.key]) {
            return othersResults.push(item.label);
          }
        });

        return [...textFieldResults, ...othersResults].filter(
          (res: string) => !!res
        );
      } else {
        return [];
      }
    }
  },
  watch: {
    "editing.dwellType": {
      async handler(val) {
        if (val && this.editing.amountDWP) {
          await this.amountGeneration();
        }
      },
      immediate: false
    },
    "editing.companyNumbers": {
      async handler(newValue, oldValue) {
        this.clearNonWeatherClaimsFields(newValue, oldValue);
        this.clearYearBuiltField(newValue, oldValue);
      },
      immediate: false
    }
  }
});
