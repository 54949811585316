
/**
 * Purpose
 * To enable us edit endorsements field on quote
 *
 * must mutate quote directly from here
 * and fetch quote
 */
import EndorsementsEditor from "./EndorsementsEditor.vue";
import MandatoryEndorsementsViewer from "./MandatoryEndorsementsViewer.vue";
import Vue from "vue";
import { quoteMapMutations, quoteMapState } from "@/store/modules/quote";
import {
  endorsementMapActions,
  endorsementMapGetters,
  endorsementMapMutations
  // endorsementMapState
} from "@/store/modules/endorsements";
import {
  getContentsAmountPerBuildingAmount,
  hasSeacoastSeaTerrData
} from "@/forms/utils/index";
import { formatCurrencyFloat } from "@/plugins/filters";
import { checkHO401A_HO101B_HO101 } from "@/forms/utils";
import { isEqual, get, cloneDeep } from "lodash";
import { contentsAmountMaxValue } from "@/helpers/constants";
import { quote as quoteStrings } from "@/strings";
import { IEndorsement } from "@/store/modules/endorsements/types";
import { ICompanyTerritories } from "@/store/modules/gbshelp/types";

interface IEndorsementsData {
  key: number;
  endorsementsResponse: any;
  loading: boolean;
  mandatoryEndorsements: any[];
  optionalEndorsements: any[];
  endorsementsToIgnore: any[];
  searchValue: string;
}

export default Vue.extend({
  name: "rating-endorsements",
  components: { EndorsementsEditor, MandatoryEndorsementsViewer },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(): IEndorsementsData {
    return {
      key: 0,
      endorsementsResponse: {},
      mandatoryEndorsements: [],
      optionalEndorsements: [],
      loading: false,
      endorsementsToIgnore: [],
      searchValue: ""
    };
  },
  mounted() {
    if (
      this.editing &&
      this.editing.policyType &&
      this.editing.companyNumbers.length > 0 &&
      (this.editing?.formattedEndorsements?.mandatoryEndorsements.length ||
        this.editing?.formattedEndorsements?.optionalSortedEndorsement.length)
    ) {
      this.optionalEndorsements = this.clonedEndorsements.optionalSortedEndorsement;
      this.mandatoryEndorsements = this.clonedEndorsements.mandatoryEndorsements;
      this.saveMandatoryEndorsements();
    }
  },
  methods: {
    ...quoteMapMutations({
      editingField: "SET_EDIT_FIELD",
      updatingEndorsements: "UPDATE_ENDORSEMENTS",
      updatingEndorsementField: "UPDATE_ENDORSEMENT_FIELD",
      resetMandatoryEndorsements: "RESET_MANDATORY_ENDORSEMENTS",
      setOverridesContentsAmount: "SET_CONTENTS_AMOUNTS_COMPANY_OVERRIDES"
    }),
    handleEndorsementHasErrors($event: boolean) {
      this.$emit("quoteEndorsementsHaveErrors", $event);
    },
    ...endorsementMapActions(["getEndorsements"]),
    ...endorsementMapMutations({
      setEndorsementValue: "SET_ENDORSEMENT_VALUE"
    }),
    clearSearchQuery() {
      this.searchValue = "";
    },

    getEndorsementByFormUsage(
      endorsements: IEndorsement[],
      formUsage: "Optional" | "Mandatory"
    ) {
      return endorsements.filter(
        endorsement => endorsement.formUsage === formUsage
      );
    },

    filterMatchingEndorsements(
      sourceEndorsements: IEndorsement[],
      targetEndorsements: IEndorsement[],
      matchKey: string
    ) {
      return sourceEndorsements.filter((endorsement: any) =>
        targetEndorsements.some(
          target => target.formNumber === endorsement[matchKey]
        )
      );
    },

    async fetchEndorsements(queryObject: any): Promise<any> {
      this.loading = true;
      try {
        const endorsements = await this.getEndorsements(queryObject);

        if (endorsements.length) {
          const mandatoryEndorsementsList = this.getEndorsementByFormUsage(
            endorsements,
            "Mandatory"
          );
          const optionalEndorsementsList = this.getEndorsementByFormUsage(
            endorsements,
            "Optional"
          );

          this.mandatoryEndorsements =
            mandatoryEndorsementsList.length > 0
              ? this.filterMatchingEndorsements(
                  this.clonedEndorsements?.mandatoryEndorsements || [],
                  mandatoryEndorsementsList,
                  "formNumber"
                )
              : [];

          this.optionalEndorsements =
            optionalEndorsementsList.length > 0
              ? this.filterMatchingEndorsements(
                  this.clonedEndorsements?.optionalSortedEndorsement || [],
                  optionalEndorsementsList,
                  "ref"
                )
              : [];
        } else {
          this.mandatoryEndorsements = [];
          this.optionalEndorsements = [];
        }
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    async saveMandatoryEndorsements(): Promise<void> {
      for (let mandatoryEndorsement of this.endorsementsFiltered) {
        const mandatorySet = get(this.editing, "endorsements", []).find(
          (endorsement: any) => {
            return endorsement.formNumber === mandatoryEndorsement.formNumber;
          }
        );
        if (mandatorySet === undefined || !mandatorySet) {
          this.updatingEndorsements({ action: "add", ...mandatoryEndorsement });
        }
      }
      this.$emit("save");
    },
    maybeResetMandatoryEndorsements() {
      const quoteMandatoryEndorsements =
        this.editing && this.editing.endorsements
          ? this.editing.endorsements.filter((endorsement: any) => {
              return endorsement.formUsage === "Mandatory";
            })
          : [];
      if (
        quoteMandatoryEndorsements.length &&
        this.endorsementsFiltered.length !== quoteMandatoryEndorsements.length
      ) {
        this.resetMandatoryEndorsements(this.endorsementsFiltered);
        this.$emit("change");
      }
    },
    resetOptionalEndorsements() {
      const removeOptionalEndorsements = [];
      if (this.editing && this.editing?.endorsements) {
        for (let endo of this.editing?.endorsements) {
          if (
            !this.editing.companyNumbers.some((item: any) =>
              endo?.companies?.includes(item)
            )
          ) {
            removeOptionalEndorsements.push(endo);
          }
        }

        for (let endorsement of removeOptionalEndorsements) {
          this.updatingEndorsements({ action: "remove", ...endorsement });
        }

        this.$emit("change");
      }
    },
    handleChangeCheckBoxHandler(change: any, event: boolean) {
      if (event && change && change.ref === "HO-160A") {
        this.$appNotify({
          title: "Endorsement HO-160A Notice",
          type: "warning",
          message: quoteStrings.endorsementHO160ANotice,
          position: "top-right"
        });
      }
      change.selected = !change.selected;
      change.showEdits = true;
      const action = change.selected == true ? "add" : "remove";
      const done = this.checkingFor101and380(change);
      const contentsAmountExists = this.checkIfContentsAmount(change);
      if (done || !contentsAmountExists) {
        return;
      }
      const hasCompanyOverrides =
        this.editing.amountDWPCompanyOverrides.length > 0;

      const endorsementForUpdate = this.optionalEndorsements.find(
        (endorsement: any) => {
          return endorsement.ref === change.ref;
        }
      );
      this.updatingEndorsements({ action: action, ...endorsementForUpdate });

      const endorsementsArray = this.optionalEndorsements.map(
        (endorsement: any) => {
          return endorsement.formNumber;
        }
      );

      if (
        checkHO401A_HO101B_HO101(change.ref, endorsementsArray) &&
        action == "add" &&
        change.selected &&
        this.editing.policyType === "H"
      ) {
        const newContentsAmount = getContentsAmountPerBuildingAmount(
          change.ref
        );
        this.editingField({
          key: "amountUPP",
          value: newContentsAmount
        });

        this.setOverridesContentsAmount({
          endorsementToCheck: change.ref,
          action
        });

        const str =
          newContentsAmount === contentsAmountMaxValue
            ? "maximum"
            : "60 % of building amount";

        const message = hasCompanyOverrides
          ? `Contents amount is now ${str} for the corresponding companies and form types because ${change.ref} was selected`
          : `Contents amount is now ${formatCurrencyFloat(
              newContentsAmount
            )} (${str}) because ${change.ref} was selected`;

        this.$appNotifySuccess(message);
      } else if (
        action == "remove" &&
        (this.checkIfOthersNotAdded(change.ref) || hasCompanyOverrides) &&
        this.editing.policyType === "H"
      ) {
        this.setOverridesContentsAmount({
          endorsementToCheck: change.ref,
          action
        });
        const newContentsAmount = getContentsAmountPerBuildingAmount();
        this.editingField({
          key: "amountUPP",
          value: newContentsAmount
        });
        this.$appNotifySuccess(
          `Contents amount has been reset because ${change.ref} was de-selected`
        );
      }
      this.$emit("change");
    },
    inputValueChangedHandler(value: any, option: any): void {
      this.updatingEndorsementField({
        formNumber: option.ref,
        value: {
          key: value.ref,
          value: value.value
        }
      });
      this.$emit("change");
    },
    checkIfOthersNotAdded(endorsementClicked: string) {
      const specialEndorsements = ["HO-401", "HO-101B", "HO-101"];
      const endorsementsArray = this.editing.endorsements.map(
        (endorsement: any) => {
          return endorsement.formNumber;
        }
      );
      const theyExist = endorsementsArray.some(
        (e: string) => specialEndorsements.indexOf(e) >= 0
      );

      const specialsClicked =
        specialEndorsements.indexOf(endorsementClicked) > -1;
      return !theyExist && specialsClicked;
    },
    checkingFor101and380(change: any) {
      const endorsement101Selected = this.editing.endorsements.find(
        (e: any) => {
          return e.formCode === "101";
        }
      );
      const endorsement380Selected = this.editing.endorsements.find(
        (e: any) => {
          return e.formCode === "380";
        }
      );
      if (
        (this.editing.companyNumbers.includes(26) ||
          this.editing.companyNumbers.includes(28)) &&
        ((change.formCode === "380" &&
          change.selected &&
          endorsement101Selected) ||
          (change.formCode === "101" &&
            change.selected &&
            endorsement380Selected))
      ) {
        if (endorsement101Selected) {
          this.updatingEndorsements({
            action: "remove",
            ...endorsement101Selected
          });
        }
        if (endorsement380Selected) {
          this.updatingEndorsements({
            action: "remove",
            ...endorsement380Selected
          });
        }
        const match101 = this.optionalEndorsements.find((endorsement: any) => {
          return endorsement.formCode === "101";
        });
        const match380 = this.optionalEndorsements.find((endorsement: any) => {
          return endorsement.formCode === "380";
        });
        if (match101) {
          match101.selected = false;
        }
        if (match380) {
          match380.selected = false;
        }
        change.selected = false;
        this.$appNotify({
          title: "Conflict",
          type: "error",
          message: this.__getText("fields", "chooseCorrectEndorsement"),
          duration: 0,
          position: "bottom-right"
        });
        return true;
      }
      return false;
    },
    checkIfContentsAmount(change: any) {
      if (change.formCode === "002" && this.editing?.amountUPP <= 0) {
        change.selected = false;
        this.$appNotify({
          title: "Error",
          type: "error",
          message:
            "TDP-002 requires a value for contents. Please update the contents amount and re-select this endorsement.",
          position: "top-right",
          offset: 100
        });
        return false;
      }
      return true;
    },

    searchEndorsements(searchValue: string) {
      const searchQuery = { __query: searchValue };
      let newQuery = { ...this.defaultQuery, ...searchQuery };

      if (
        (searchValue && searchValue.length >= 3) ||
        searchValue.length === 0
      ) {
        this.fetchEndorsements(newQuery);
      }
      const { mandatoryEndorsementsViewer, endorsementsEditor } = this
        .$refs as any;
      if (mandatoryEndorsementsViewer) {
        mandatoryEndorsementsViewer.expanded = true;
      }
      if (endorsementsEditor) {
        endorsementsEditor.expanded = true;
      }
    },
    populateEndorsement(
      field: string,
      formCode: string,
      conditionCallback: (value: "yes" | "no") => boolean
    ) {
      const fieldValue = get(this.editing, field, "").toLowerCase();
      const endorsements: IEndorsement[] = get(
        this.editing,
        "endorsements",
        []
      );
      const existingEndorsement = endorsements.find(
        (endorsement: { formCode: string }) => endorsement.formCode === formCode
      );
      const endorsementByFormCode = this.getEndorsementByFormCode(formCode);
      const shouldRemove =
        existingEndorsement && !conditionCallback(fieldValue);
      const shouldAdd =
        !existingEndorsement &&
        conditionCallback(fieldValue) &&
        endorsementByFormCode &&
        Object.keys(endorsementByFormCode).length;

      if (shouldRemove || shouldAdd) {
        const endorsementAction = shouldRemove
          ? { action: "remove", ...existingEndorsement }
          : {
              action: "add",
              ...endorsementByFormCode,
              selected: true
            };

        const message = shouldRemove
          ? `Endorsement ${existingEndorsement?.formNumber} has been removed`
          : `Endorsement ${endorsementAction.formNumber} has been added`;

        this.updatingEndorsements(endorsementAction);
        this.$emit("save");
        this.$appNotify({
          message,
          type: shouldRemove ? "warning" : "info",
          title: shouldRemove ? "Alert" : "Info"
        });
      }
    },
    populateDogsOrExoticBreedsEndorsement() {
      this.populateEndorsement(
        "dogsOrExoticBreeds",
        "82673",
        (value: "yes" | "no") =>
          value === "yes" && hasSeacoastSeaTerrData(this.companyTerritories)
      );
    },

    populateTrampolineOnPremisesEndorsement() {
      this.populateEndorsement(
        "trampolineOnPremises",
        "80108",
        (value: "yes" | "no") => {
          const approvedFence = get(
            this.editing,
            "trampolineApprovedFence",
            ""
          ).toLowerCase();
          return (
            value === "yes" &&
            approvedFence === "no" &&
            !hasSeacoastSeaTerrData(this.companyTerritories)
          );
        }
      );
    },
    getEndorsementByFormCode(formCode: string) {
      const endorsements: IEndorsement[] = get(
        this.editing,
        "quoteCompanyEndorsements",
        []
      );
      return endorsements.find(
        endorsement => endorsement.formCode === formCode
      );
    }
  },
  computed: {
    ...quoteMapState(["editing"]),
    ...endorsementMapGetters(["endorsements"]),
    defaultQuery() {
      let query: {
        appliesTo: string;
        companies__in?: number;
        __query?: string;
      } = {
        appliesTo: this.editing.policyType
      };
      if (this.editing.companyNumbers) {
        query["companies__in"] = this.editing.companyNumbers.join();
      }
      return query;
    },
    companyTerritories(): ICompanyTerritories[] {
      return get(
        this.editing,
        "territoryData.territoryInfo.companyTerritories",
        []
      );
    },
    endorsementsFiltered(): any {
      return this.mandatoryEndorsements.filter(
        (endorsements: any) =>
          !this.endorsementsToIgnore.includes(endorsements.formCode)
      );
    },
    clonedEndorsements(): any {
      const formattedEndorsements = get(
        this.editing,
        "formattedEndorsements",
        {}
      );
      return cloneDeep(formattedEndorsements);
    }
  },
  watch: {
    "editing.formattedEndorsements": {
      handler(newValue) {
        if (newValue) {
          this.optionalEndorsements = this.clonedEndorsements.optionalSortedEndorsement;
          this.mandatoryEndorsements = this.clonedEndorsements.mandatoryEndorsements;
          this.saveMandatoryEndorsements();
        }
      }
    },
    "editing.companyNumbers": {
      handler(newValue: any, oldValue: any) {
        if (!isEqual(newValue, oldValue) && this.editing) {
          const policyType = get(this.editing, "policyType", "");
          let query: { appliesTo: string; companies__in?: number } = {
            appliesTo: policyType
          };
          const companyNumbers = get(this.editing, "companyNumbers", []);
          if (companyNumbers.length > 0) {
            query["companies__in"] = newValue.join();
          }
          this.maybeResetMandatoryEndorsements();
          this.resetOptionalEndorsements();
          this.saveMandatoryEndorsements();
        }
      },
      immediate: false
    },
    "editing.dogsOrExoticBreeds": {
      handler(newValue) {
        const dogsOrExoticBreeds = newValue;
        if (!dogsOrExoticBreeds) return;
        this.populateDogsOrExoticBreedsEndorsement();
      }
    },
    "editing.trampolineOnPremises": {
      handler(newValue) {
        if (newValue) {
          this.populateTrampolineOnPremisesEndorsement();
        }
      }
    },
    "editing.trampolineApprovedFence": {
      handler(newValue) {
        if (newValue) {
          this.populateTrampolineOnPremisesEndorsement();
        }
      }
    }
  }
});
