import Vue from "vue";
import {
  isColonial,
  checkIfIsAtlasCompany,
  hasSeacoastSeaTerrData
} from "@/forms/utils/index";
import {
  IsYear,
  validateAtlasRoofYear,
  validateColonialRoofYear
} from "@/helpers/validateRoofYear";
import { quoteMapState } from "@/store/modules/quote";
import { IWindIncluded } from "@/store/modules/quote/types";
import { ICompanyTerritories } from "@/store/modules/gbshelp/types";
export default Vue.extend({
  data: function() {
    return {
      needsApproval: false,
      messages: [] as string[]
    };
  },
  methods: {
    checkYearOfRoof({
      yearOfRoof,
      yearBuild,
      roofMaterial,
      companyNumbers,
      ratingCompany,
      formType,
      windIncluded,
      companyTerritories
    }: {
      yearOfRoof: number;
      yearBuild: number;
      roofMaterial: string;
      companyNumbers: number[];
      ratingCompany?: number;
      formType?: string;
      windIncluded?: IWindIncluded;
      companyTerritories?: ICompanyTerritories[];
    }): {
      success: boolean;
      message: string;
      type: string;
    } {
      if (!IsYear(yearOfRoof.toString())) {
        return {
          success: false,
          message: "Valid Roof Installation Year is required",
          type: "invalid"
        };
      }
      if (yearOfRoof && yearBuild && yearOfRoof < yearBuild) {
        this.needsApproval = false;
        return {
          success: false,
          message: "Error – Roof Year Prior to Year Built",
          type: "invalid"
        };
      }
      const currentYear = new Date().getFullYear();
      const roofAge = currentYear - yearOfRoof;

      if (isColonial(companyNumbers) && roofMaterial) {
        if (ratingCompany && companyTerritories?.length) {
          companyTerritories = companyTerritories.filter(
            territory => territory.companyNumber === ratingCompany
          );
        }

        const options = {
          roofAge,
          isSeacoast: Boolean(
            companyTerritories && hasSeacoastSeaTerrData(companyTerritories)
          ),
          roofMaterial,
          agentCode: this.editing.agentCode,
          windIncluded,
          formType,
          yearOfRoof: this.editing.yearOfRoof,
          yearBuild: this.editing.yearBuild
        };
        const response = validateColonialRoofYear(options);

        if (response?.type === "call") {
          if (response.reason) {
            this.messages.push(response.reason);
          }
          this.needsApproval = true;
        } else {
          this.needsApproval = false;
        }
        return response;
      }

      if (checkIfIsAtlasCompany(companyNumbers)) {
        const response = validateAtlasRoofYear(15, yearOfRoof, roofMaterial);
        if (response?.type === "call") {
          if (response.reason) {
            this.messages.push(response.reason);
          }
          this.needsApproval = true;
        } else {
          this.needsApproval = false;
        }
        return response;
      }

      return {
        success: false,
        message: "",
        type: ""
      };
    }
  },
  computed: {
    ...quoteMapState(["editing"])
  }
});
