
import { isArray } from "lodash";
import Vue from "vue";
import HO210Fields from "./Components/HO210Fields.vue";
import HO215Fields from "./Components/HO215Fields.vue";
import HO225Fields from "./Components/HO225Fields.vue";
import DisplayHO215Data from "./Components/DisplayHO215Data.vue";
import GoogleAutoComplete from "@/components/User/GoogleAutoComplete.vue";
import CheckBox from "@/components/FormBuilder/Components/CheckBox.vue";
import RadioButtonGroup from "@/components/FormBuilder/Components/RadioButtonGroup.vue";
import { quoteMapState } from "@/store/modules/quote";

interface IData {
  hasError: boolean;
  endorsementValueHasError: boolean;
}

export default Vue.extend({
  name: "SingleEndorsement",
  components: {
    RadioButtonGroup,
    CheckBox,
    GoogleAutoComplete
  },
  data: (): IData => ({
    hasError: false,
    endorsementValueHasError: false
  }),
  props: {
    option: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    requiredEndorsementFormCodes: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    handleChangeCheckBox($event: any, option: any) {
      if ($event && option.values) {
        this.validateEndorsement(option);
      } else {
        this.$emit("endorsementValuesHasErrors", false);
      }
      this.$emit("handleChangeCheckBox", option, $event);
    },
    checkValidations(errors: string[]) {
      this.hasError = errors.length > 0;
      if (this.hasError) this.$emit("endorsementHasErrors", this.hasError);
      else
        this.$emit("endorsementValuesHasErrors", this.endorsementValueHasError);
    },
    inputValueChanged($event: any, value: any, option: any) {
      value.value = $event;
      this.validateEndorsement(option);
      const error = this.hasError || this.endorsementValueHasError;
      this.$emit("inputValueChanged", value, option, error);
    },
    inputValueChangedCustom($event: any, option: any) {
      this.hasError = this.validateCustomEndorsement(option);
      this.endorsementValueHasError = this.validateCustomEndorsement(option);

      const error = this.hasError || this.endorsementValueHasError;
      const value = this.option.values.find(
        (opt: any) => opt.ref === $event.ref
      );
      this.$emit("inputValueChangedCustom", value || $event, option, error);
    },
    validateNumberOfWaterCraft(endorsementValues: any[]) {
      const waterCraftDataOption = endorsementValues.find(
        (option: { ref: string; value: any }) => option.ref === "waterCraftData"
      );
      const numberOfWaterCrafts = endorsementValues.find(
        (option: { ref: string; value: any }) =>
          option.ref === "m_fmNumWaterCraft"
      );
      if (
        !waterCraftDataOption ||
        !Object.keys(waterCraftDataOption.value).length
      ) {
        return true;
      }

      const waterCraftDataCount = Object.values(waterCraftDataOption.value)
        .length;

      return numberOfWaterCrafts.value !== waterCraftDataCount;
    },
    validateCustomEndorsement(endorsement: any) {
      let hasError = false;
      if (endorsement && endorsement.values) {
        for (const option of endorsement.values) {
          if (
            option &&
            option.ref &&
            (option.ref === "m_fmNumWaterCraft" ||
              option.ref === "waterCraftData")
          ) {
            return this.validateNumberOfWaterCraft(endorsement.values);
          } else {
            hasError = Boolean(option.value);
          }
        }
        return hasError;
      }
      return false;
    },
    validateEndorsement(option: any) {
      if (option && option.values && isArray(option.values)) {
        const requiredFieldTypes = [
          "radioButtonGroup",
          "selectField",
          "textField",
          "textArea",
          "checkbox"
        ];
        const filtered = option.values.filter((opt: any) => {
          return (
            this.requiredEndorsementFormCodes.includes(option.formCode) &&
            requiredFieldTypes.includes(opt.type)
          );
        });
        const result = !filtered.some((opt: any) => {
          if (option.formCode === "201") {
            return opt.value || opt.value === 0;
          }

          if (option.formCode === "009") {
            return this.validateEndorsement009(option);
          }
          return opt.value;
        });
        this.endorsementValueHasError = result;
      }
      this.$emit("endorsementValuesHasErrors", this.endorsementValueHasError);
    },
    displayEndorsementField(value: Record<string, any>) {
      {
        if (value.label) {
          return `${value.label}: ${value.value}`;
        }
        if (
          (value.ref && value.ref !== "waterCraftData") ||
          value.ref !== "m_fmNumWaterCraft"
        ) {
          `${value.label || value.ref}: ${value.value}`;
        }
        return "";
      }
    },
    validateEndorsement009(endorsementOption: {
      values: Record<string, any>;
    }): boolean {
      const unscheduledGlass = endorsementOption.values.find(
        (opt: { ref: string; value: boolean }) => opt.ref === "m_fmSchelGlass1"
      );
      const scheduledGlass = endorsementOption.values.find(
        (opt: { ref: string; value: boolean }) =>
          opt.ref === "m_fmUnSchelGlass1"
      );
      const averageAmountForScheduledGlass = endorsementOption.values.find(
        (opt: { ref: string; value: boolean }) =>
          opt.ref === "m_fmAmtSchelGlass"
      );
      if (unscheduledGlass.value && !scheduledGlass.value) return true;
      const convertedAverageAmountForScheduledGlass = parseFloat(
        averageAmountForScheduledGlass.value
      );

      return Boolean(
        scheduledGlass.value &&
          convertedAverageAmountForScheduledGlass &&
          typeof convertedAverageAmountForScheduledGlass === "number" &&
          !isNaN(convertedAverageAmountForScheduledGlass) &&
          Boolean(convertedAverageAmountForScheduledGlass)
      );
    },
    getFieldComponent(option: any): any {
      const { formCode } = option as any;

      switch (formCode) {
        case "210":
          return HO210Fields;
        case "215":
          return HO215Fields;
        case "225":
          return HO225Fields;
        default:
          return "div";
      }
    },
    showEdits($event: any, option: any) {
      if (
        !option.showEdits &&
        (this.territoryInfo.terr == "8X" ||
          this.territoryInfo.terr == "9X" ||
          this.territoryInfo.terr == "10X" ||
          this.territoryInfo.terr == "01X") &&
        (option.formCode == "001" ||
          option.formCode == "01A" ||
          option.formCode == "140" ||
          option.formCode == "140B")
      ) {
        this.$emit(
          "showModal",
          "Wind coverage is not allowed in this territory."
        );
      }

      if (
        !option.showEdits &&
        option.formCode == "002" &&
        this.editing?.amountUPP <= 0
      ) {
        this.$emit(
          "showModal",
          "TDP-002 requires a value for contents. Please update the contents amount and re-select this endorsement."
        );
      }
      option.showEdits = !option.showEdits;
    },
    getDisplayComponent(option: any): any {
      const { formCode } = option as any;

      switch (formCode) {
        case "215":
          return DisplayHO215Data;
        default:
          return "div";
      }
    }
  },
  computed: {
    ...quoteMapState(["editing"]),
    territoryInfo(): any {
      return this.editing.territoryData.territoryInfo;
    }
  }
});
